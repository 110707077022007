<template>
  <div class="account">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="account__header__items d-flex align-items-center justify-content-between"
      >
        <div class="account__logo"></div>
      </div>
    </div>
    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <!-- <h1 class="account__head__title">Welcome</h1> -->
            </div>
            <div class="account__body">
              <transition name="fade" mode="out-in">
                <keep-alive>
                  <component
                    :is="componentList[activeComponentIndex]"
                    :activeComponentIndex="activeComponentIndex"
                    :pageIsVerifyingOTP="pageIsVerifyingOTP"
                    @go-to-next-section-with-org="handleNextSectionWithForm"
                    @go-to-next-section="goToNextSection"
                    @go-to-previous-section="handlePrevSection"
                    @send-otp="initiateUserOTP"
                    @verify-otp="verifyOTP"
                  />
                </keep-alive>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              <!-- For Access User -->
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title mb-5" style="font-size: 16px; line-height: 26px;">
                We are creating your company space. Click on continue to receive
                a unique login email when we are finished. Thanks for choosing
                Core Lending 
              </h5>
              <p class="form__label">You will be redirected to login once successful</p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="createOrganisation"
                >
                  {{ process ? "Processing..." : "Continue" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dynamicComponentMixin from "@/mixins/dynamicComponentMixin";
import OrgDetails from "./orgDetails.vue";
import OrgAdminDetails from "./orgAdminDetails.vue";
import EmailVerification from "./emailVerification.vue";
import ApiService from "@/core/services/noauth.service";
import axios from "axios";

export default {
  name: "OnboardingIndex",
  mixins: [dynamicComponentMixin],
  data() {
    return {
      process: false,
      pageIsVerifyingOTP: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
      componentList: [OrgDetails, OrgAdminDetails, EmailVerification],
      orgDetails: {
        organisationName: "",
        organisationEmailAddress: "",
        organisationPhoneNo: "",
        companyAddress: "",
        decimalMark: ",",
        domain: "",
      },
      adminDetails: {
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userTitle: "mr",
        userMobilephone: "",
        userHomephone: "",
        username: "",
      },
      message: "",
      orgHeader: "",
      confirm_delete: "",
    };
  },

  computed: {
    getAccessUrl() {
      return "http://tenantaccess.proleanfinancials.biz/api/ProleanUser/";
    },
  },

  methods: {
    goToNextSection(form) {
      const firstName = form.fullName.split(" ").slice(0, 1).join(" ");
      const lastName = form.fullName.split(" ").slice(-1).join(" ");
      this.adminDetails = {
        ...form,
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: form.email,
        userMobilephone: form.PhoneNumber,
        userHomephone: form.PhoneNumber,
        userTitle: "mr",
      };
      this.handleNextSection();
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
    },

    handleNextSectionWithForm(form) {
      this.orgDetails = { ...form };
      this.handleNextSection();
    },

    handlePrevSection() {
      this.handlePreviousSection();
    },

    confirmDelete() {
      this.confirm_delete = false;
    },

    initiateUserOTP() {
      const p = {
        email: this.adminDetails.email,
      };
      ApiService.post(
        `${this.getAccessUrl}initiateemailverificationOtp`,
        p
      ).then((response) => {
        this.message = response.data.message;
      });
    },

    createAdminUSer() {
      this.process = true;
      const headers = {
        'organizationKey': `${this.orgHeader}`,
      };
      axios
        .post(
          "http://tenantaccess.proleanfinancials.biz/api/ProleanUser/CreateAdminUser",
          this.adminDetails,
          {
            headers: headers
          }
        )
        .then((res) => {
          this.message = res.data.message;
          this.alertSuccess(res.data.message);
          this.process = false;
          this.confirm_delete = false;
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 1500)
        })
        .catch((error) => {
          this.alertError(error.response.data);
          this.process = false;
        });
    },

    createOrganisation() {
      this.process = true;
      ApiService.post(
        `http://tenantgeneralsetup.proleanfinancials.biz/api/OrganisationDetails`,
        this.orgDetails
      )
        .then((response) => {
          this.orgHeader = response.data.message;
          this.process = false;
          this.createAdminUSer();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.process = false;
        });
    },

    verifyOTP(token) {
      this.pageIsVerifyingOTP = true;
      const payload = {
        token,
        email: this.adminDetails.email,
      };
      ApiService.post(`${this.getAccessUrl}verifyemailotp`, payload)
        .then((response) => {
          this.pageIsVerifyingOTP = false;
          this.message = response.data.message;
          this.confirm_delete = true;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.pageIsVerifyingOTP = false;
          this.alertError(error.response.data.message);
        });
    },
  },
};
</script>
