<template>
  <!-- Onboarding form -->
  <div class="account__form">
    <p class="account__head__title mb-5">Organization Details</p>
    <form class="form" @submit.prevent="login">
      <div class="row">
        <div class="col-6">
          <BaseInput
            label="Company name"
            placeholder="Enter company name"
            v-model="form.organisationName"
            type="text"
            class="mb-0"
          >
          </BaseInput>
          <div class="error" v-if="!$v.form.organisationName.required">
            <span class="text-danger">This is required</span>
          </div>
        </div>

        <div class="col-6">
          <BaseInput
            label="Company email"
            placeholder="Enter company email"
            v-model="form.organisationEmailAddress"
            type="text"
            class="mb-0"
          >
          </BaseInput>
          <div class="error" v-if="!$v.form.organisationEmailAddress.required">
            <span class="text-danger">This is required</span>
          </div>
          <div class="error" v-if="!$v.form.organisationEmailAddress.email">
            <span class="text-danger">Enter a valid email</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <BaseInput
            label="Unique name"
            placeholder="Unique name"
            v-model="form.domain"
            type="text"
            class="mt-5 mb-0"
          >
          </BaseInput>
        </div>

        <div class="col-6">
          <BaseInput
            label="Company URL"
            placeholder="Unique name"
            :value="companyUrl"
            type="text"
            :disabled="true"
            v-if="form.domain"
            class="mt-5 mb-0"
          >
          </BaseInput>
        </div>
      </div>

      <BaseInput
        label="Company Phone number"
        placeholder="Enter company phone number"
        type="text"
        v-model="form.organisationPhoneNo"
        class="mt-5 mb-0"
      >
      </BaseInput>

      <TextArea
        label="Company address"
        placeholder="Enter company address"
        v-model.trim="form.organisationAddress"
        cols="5"
        rows="5"
        class="mt-5 mb-5"
      />

      <div
        class="
          form__item form__action
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <div style="position: relative; z-index: 3" class="row">
          <button
            type="submit"
            role="button"
            href="#"
            class="button form__button form__button--lg"
          >
            {{ process ? "please wait..." : "Continue" }}
          </button>
          <img
            style="
              position: absolute;
              left: -5rem;
              top: -2.5rem;
              z-index: -1;
            "
            src="/assets/img/dotted-bg.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/_base-Input.vue";
import TextArea from "@/components/Inputs/_base-text-area.vue";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "orgDetails",
    data() {
    return {
      form: {
        organisationName: "",
        organisationEmailAddress: "",
        organisationPhoneNo: "",
        organisationAddress: "",
        decimalMark: ",",
        domain: "",
      },
      process: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },

  methods: {
    login() {
      this.process = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.process = false;
        return;
      } else {
        setTimeout(() => {
          this.process = false;
          this.$emit("go-to-next-section-with-org", this.form);
        }, 300);
      }
    },
  },

  computed: {
    companyUrl() {
      return `${this.form.domain}.bookeet.io`;
    }
  },

  validations: {
    form: {
      organisationName: {
        required,
      },
      organisationEmailAddress: {
        required,
        email,
      },
    },
  },

  components: {
    BaseInput,
    TextArea,
  },
}
</script>
