<template>
  <!-- Onboarding form -->
  <div class="account__form">
    <p class="account__head__title mb-3">Email Verification</p>
    <p class="account__head__subtitle">Enter the OTP sent to your email</p>

    <form class="form">
      <div class="mb-5">
        <BaseInput
          v-model="form.passcode"
          type="text"
          class="mt-5 mb-0 form-input"
          :maxLength="6"
          :className="'form-input-input'"
        >
        </BaseInput>
      </div>

      <div
        class="form__item form__action d-flex align-items-center justify-content-between"
      >
        <div style="position: relative; z-index: 3" class="row">
          <button
            type="submit"
            role="button"
            href="#"
            class="button form__button form__button--lg"
            @click.prevent="verifyOTP"
            :disabled="passcodeIsEmpty"
          >
            {{ pageIsVerifyingOTP ? "please wait..." : "Continue" }}
          </button>

          <a
            role="button"
            class="modal__close form__action__text form__action__text-- text--capital ml6"
            @click="handlePrevSection"
            style="display: flex; align-items: center;"
          >
            Go back
          </a>
          <img
            style="position: absolute; left: -5rem; top: -2.5rem; z-index: -1"
            src="/assets/img/dotted-bg.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/_base-Input.vue";
import { required } from "vuelidate/lib/validators";
// import ApiService from "@/core/services/noauth.service";

export default {
  name: "EmailVerification",
  props: {
    pageIsVerifyingOTP: Boolean,
  },
  data() {
    return {
      form: {
        passcode: "",
      },
      process: false,
      focusIndex: 1,
      message: "",
    };
  },

  validations: {
    form: {
      passcode: {
        required,
      },
    },
  },

  computed: {
    getAccessUrl() {
      return "http://tenantaccess.proleanfinancials.biz/api/ProleanUser/";
    },
    passcodeIsEmpty() {
      return this.form.passcode === '';
    }
  },

  methods: {
    handlePrevSection() {
      this.$emit("go-to-previous-section");
    },

    initiateUserOTP() {
      this.$emit("send-otp");
    },

    verifyOTP() {
      this.$emit("verify-otp", this.form.passcode);
    },
  },

  components: {
    BaseInput,
  },

  mounted() {
    this.initiateUserOTP();
  },
};
</script>
<style lang="scss" scoped>
.form--validation {
  display: flex;
  flex-wrap: nowrap;
  //column-gap: 20px;
}
</style>
